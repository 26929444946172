export const fetchData = async (page = 1, pageSize = 10) => {
  try {
    const res = await fetch(`https://snapus.pythonanywhere.com/api/images_with_random_num/?page=${page}&page_size=${pageSize}`);
    const images = await res.json();

    return images;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};


  // https://snapus.pythonanywhere.com/api/images_with_random_num/