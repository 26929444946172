import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import ImageZoom from './component/tool';
import { useEffect, useState } from 'react';
import Trems from './component/trems';
import styled from 'styled-components';
const StyledApp = styled.div`
  position: relative;
  min-height: 100vh;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.bg});
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
`;

function App() {
  const [addedImagee, setAddedImages] = useState(false);
  const [bg,setbg]=useState('')
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await fetch('https://snapus.pythonanywhere.com/api/info/4/');
        const result = await response.json();
        setbg(result.webbg);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchInfo();
  }, []);
  return (
<StyledApp bg={bg && bg}>     
     <Router  basename="/">
      <Routes>
        <Route path="/" element={<ImageZoom addedImagee={addedImagee} setAddedImages={setAddedImages}  />} />
        <Route path="/trems/:lan" element={<Trems/>}/>
      </Routes>
    </Router>
</StyledApp>

  );
}

export default App;
