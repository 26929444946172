import { useState } from "react";

import image1 from "../assest/Visa_Inc._logo.svg.png"
import image2 from "../assest/Mastercard-logo.svg.png"
import image3 from "../assest/Stc_pay.png"
import image4 from "../assest/768px-Apple_Pay_logo.svg.png"

import "./upload.css"
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from "react-router-dom";
function Credit({ setCardData, cardData, isChecked, onCheckboxChange }) {

    const [focus, setfocus] = useState('')

    const calendarIconStyle = {
        filter: 'invert(1)', // Inverts the color of the icon (white)
    };



    // Generalized handleChange function
    const handleChange = (event) => {
        const { name, value } = event.target;

        // Update the state based on the input field name
        setCardData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();
    
        // Ensure month and day are in double digits
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;
    
        return `${year}-${month}-${day}`;
      };
    return (
        <div >
            <form>
                <div className="row ">
                    <div class="form-floating  col-12">
                        <input type="text" className="form-control "
                            name='number'
                            id="floatingInput" value={cardData.number} onChange={handleChange}
                            onFocus={e => setfocus(e.target.name)}  autoComplete="off"  dir="ltr" />
                        <label for="floatingInput" className="w-100 ms-2" >Number</label>
                    </div>
                    
                    <div class="form-floating  col-12">
                        <input type="text" className="form-control"
                            name='name'
                            id="floatingInput" value={cardData.name} onChange={handleChange}
                            onFocus={e => setfocus(e.target.name)}  autoComplete="off"  dir="ltr" />
                        <label for="floatingInput" className="w-100 ms-2" >Name</label>
                    </div>
                </div>
                <div className="row">
                    <div class="form-floating  col-12">
                        <input type="month" className="form-control" style={{}}
                            name='expiry'
                            id="floatingInput" value={cardData.expiry} onChange={handleChange}
                            onFocus={e => setfocus(e.target.name)}  autoComplete="off" 
                            dir="ltr"
                            min={getCurrentDate()}
                        />
                        <label for="floatingInput" className="w-100 ms-2">Expirey</label>
                    </div>
                    <div class="form-floating  col-12">
                        <input className="form-control"
                            name='cvc'
                            type="number"
                            id="floatingInput" value={cardData.cvc} onChange={handleChange}
                            onFocus={e => setfocus(e.target.name)}
                            autoComplete="off"
                            dir="ltr" />
                        <label for="floatingInput " className="w-100 ms-2" >CVC</label>
                    </div>
                    
                </div>

            </form>
            <section className="d-flex align-items-center justify-content-between">
                <div class="form-check col-6">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked={isChecked}
                        onChange={onCheckboxChange}
                        
                    />
                    <Link  to='/trems/true'  target="_blank"  rel="noopener noreferrer" >
                            <a className="agreement" style={{fontSize:10,color:'#f29122f1'}}>agree with all terms&conditions</a>
                    </Link>
                </div>
                <div className="d-flex imagec_credit col-6  justify-content-end ">
                    <div  className="rounded   d-flex justify-content-center align-items-center">
                        <img src={image1} style={{ width: '100%',height:'100%' }} className="p-1"/>
                    </div>
                    <div  className="rounded mx-1  d-flex justify-content-center align-items-center">
                        <img src={image2} style={{ width: '100%'}}className="p-1" />
                    </div>
                    <div  className="rounded   d-flex justify-content-center align-items-center">
                        <img src={image3} style={{ width: '100%' }} className="p-1"/>
                    </div>
                    <div  className="rounded  ms-1 d-flex justify-content-center align-items-center">
                        <img src={image4} style={{ width: '100%' }}className="p-1" />
                    </div>
                </div>

            </section>

        </div>

    )
}
export default Credit;