import React, { useState, useEffect, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './imagecrop';
import "./upload.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";


const PhotoEditor = ({ image, onImageEdited, url, name, inputRef }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const containerRef = useRef(null);

  const [imageWidth, setImageWidth] = useState(200); // Default width
  const [imageHeight, setImageHeight] = useState(200); // Default height
  const [title, settitle] = useState('')

  const handleCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
    setCroppedImage(croppedImage);
  };

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        setImageWidth(img.width);
        setImageHeight(img.height);
      };
    }
  }, [image]);

  const handleRotateLeft = () => {
    setRotation((prevRotation) => prevRotation - 90);
  };

  const handleRotateRight = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  const handleSave = (nameff, titles) => {
    onImageEdited({ croppedImage, name: nameff, title: titles });
    settitle('')
    console.log("donbe");

  };

  if (!image) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return (
    <>
      <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
        <button onClick={handleRotateLeft} className='rotate-btn p-2 mb-2 me-2' style={{ width: 'auto', height: '30px' }}><FontAwesomeIcon icon={faRotateLeft} /></button>

        <div
          ref={containerRef}
          style={{
            position: 'relative',
            width: `100%`,
            height: `220px`,
          }}
        >
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={imageWidth / imageHeight} // Set aspect ratio based on image width and height
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
            rotation={rotation}
          />
        </div>
        <button onClick={handleRotateRight} className='rotate-btn  p-2 mb-2 ms-2 ' style={{ width: 'auto', height: '30px' }}><FontAwesomeIcon icon={faRotateRight} /></button>
      </div>
      <div className='btn-div d-flex  mx-2 mt-1'>
        <button onClick={() => handleSave(name, title)} className='save-btn w-50  p-2 ms-2'>Save Image</button>

        <input type="text" className="p-1 mx-2 w-50 rounded" ref={inputRef}
           placeholder='Description' value={title} autoComplete="off" onChange={(e) => settitle(e.target.value)} />

      </div>
    </>
  );
};

export default PhotoEditor;
