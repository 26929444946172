import './footer.css';
import image1 from '../logo.svg'
import image2 from "../pexels-dids-7867332 - Copy (3).jpg"
import image3 from "../logos/Picture 2_20231217234005.png"
import xlogo from "../logos/Elon Musk.jpeg"
import insta from "../logos/insta.jpeg"
import tik from "../logos/tiktok.jpeg"
import tel from "../logos/tel.jpeg"
import snap from "../logos/download logo snapchat vector svg eps png psd ai.jpeg"
import dicord from "../logos/Discord Logo _ 02 - PNG Logo Vector Downloads (SVG, EPS).jpeg"
import twich from "../logos/Download Twitch logo on transparent isolated background_.jpeg"
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
function Footer() {
  const [logo, setlogo] = useState('');
  const [social, setsocial] = useState([]);
  const [spons, setspons] = useState([]);
  const [counter, setCounter] = useState(1);
  const sliderRef = useRef(null);
  const [link1, setlink1] = useState('')
  const [link2, setlink2] = useState('')
  const [link3, setlink3] = useState('')
  const [link4, setlink4] = useState('')
  const [link5, setlink5] = useState('')
  const [link6, setlink6] = useState('')
  const [link7, setlink7] = useState('')


  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current) {
        const totalWidth = sliderRef.current.scrollWidth;
        const itemWidth = sliderRef.current.clientWidth;
        const maxScrollLeft = totalWidth - itemWidth;

        if (sliderRef.current.scrollLeft === maxScrollLeft) {
          // If at the end, reset to the beginning
          setCounter(1);
          sliderRef.current.scrollLeft = 0;
        }
      }
    };

    const interval = setInterval(() => {
      setCounter((prevCounter) => (prevCounter % 5) + 1); // Assuming there are 5 images
    }, 3000);

    // Attach scroll event listener
    if (sliderRef.current) {
      sliderRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      clearInterval(interval);
      // Remove scroll event listener on component cleanup
      if (sliderRef.current) {
        sliderRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);


  useEffect(() => {
    const nextPosition = (counter - 1) * sliderRef.current.clientWidth;
    sliderRef.current.scrollTo({
      left: nextPosition,
      behavior: 'smooth',
    });
  }, [counter]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://snapus.pythonanywhere.com/footer/logo-photos/');
        const result = await response.json();
        if (result && result.length > 0) {
          setlogo(result[0].image);
          console.log(result[0].image);
        }
      } catch (error) {
        // Handle fetch error
        console.error('Error fetching logo:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://snapus.pythonanywhere.com/footer/footer-photos/');
        const result = await response.json();
        if (result && result.length > 0) {
          setspons(result)
        }
      } catch (error) {
        // Handle fetch error
        console.error('Error fetching logo:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://snapus.pythonanywhere.com/footer/footer-links/');
        const result = await response.json();
        if (result && result.length > 0) {
          setlink1(result[0])
          setlink2(result[1])
          setlink3(result[2])
          setlink4(result[3])
          setlink5(result[4])
          setlink6(result[5])
          setlink7(result[6])
        }
      } catch (error) {
        // Handle fetch error
        console.error('Error fetching logo:', error);
      }
    };

    fetchData();
  }, []);





  return (
    <>
      <footer className="nb-footer">
        <div className="mx-3 h-100">
          <div className="row">
            <div className="col-md-3 col-sm-3 ">
              <div className="footer-info-single">
                <h2 className="title mb-2 text-center" style={{ borderLeft: '0px solid #F29222 ' }}>POWERED BY</h2>
                <div className='col-12 d-flex justify-content-center  '>
                  <img src={logo} className='rounded-circle' style={{ width: "90px", height: "90px" }} />
                </div>
              </div>
            </div>


            <div className="col-md-6 col-sm-6 px-2">
              <div className="footer-info-single" style={{ overflow: 'hidden', width: '100%' }}>
                <h2 className="title  text-center">SPONSORS</h2>
                <div className="social-media  d-flex justify-content-center" style={{ overflowX: 'scroll', width: '98%' }} ref={sliderRef}>
                  <div className='d-flex w-100 justify-content-between '>
                    {spons.map((item, index) => (
                      <div className='col-4 ' key={index}>
                        <div className='col-12 d-flex  justify-content-center'>
                          <img src={item.image} style={{ width: "5rem", height: "5rem" }} className='border-4 rounded-circle' alt={`Sponsor ${index + 1}`} />
                        </div>
                      </div>
                    ))}


                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-3">
              <div className="footer-info-single">
                <h2 className="title  text-center" >CONTACT US</h2>
                <div className='col-12'>
                  <ul className='row ps-1 p-0'>
                    <a className=' col-3 mb-1' href={link1.url}><img src={xlogo} className='w-100' style={{ borderRadius: "50%", border: '2px solid white' }} /></a>
                    <a className=' col-3 mb-1' href={link3.url}><img src={insta} className='w-100' style={{ borderRadius: "50%" }} /></a>
                    <a className=' col-3 mb-1' href={link7.url}><img src={tel} className='w-100' style={{ borderRadius: "50%" }} /></a>
                    <a className=' col-3 mb-1' href={link4.url}><img src={tik} className='w-100' style={{ borderRadius: "50%" }} /></a>
                    <a className=' col-3 mb-1' href={link5.url}><img src={dicord} className='w-100' style={{ borderRadius: "50%" }} /></a>
                    <a className=' col-3 mb-1' href={link2.url}><img src={snap} className='w-100' style={{ borderRadius: "50%" }} /></a>
                    <a className=' col-3 mb-1' href={link6.url}><img src={twich} className='w-100' style={{ borderRadius: "50%" }} /></a>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="copyright m-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                {/* Use the correct 'Link' component syntax */}
                <Link as='a' to='/trems/true' target="_blank" rel="noopener noreferrer" className='terms'>
                  TERMS & CONDITIONS
                </Link>
              </div>
              <div className="col-sm-6" />
            </div>
          </div>
        </section>
      </footer>
    </>
  )
}
export default Footer;