import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import LazyLoad from 'react-lazyload';
import { useDebounceEffect } from 'ahooks';
import imagebg from '../logos/Untitled-1.png'



function Loading({ data, len, setImageID, ImageID, bg, reff }) {
  const [numCols, setNumCols] = useState(0);
  const [numRows, setNumRows] = useState(0);
  const containerRef = useRef(null);
  const totalDivs = 1000000;
  const [containerWidth, setContainerWidth] = useState(0);
  const containerReff = useRef(null);
  const [totalSubdivs, setTotalSubdivs] = useState(totalDivs);
  const [make, setMake] = useState(false);
  const panelRef = useRef(null);

  // const calculateGridSize = useCallback(() => {
  //   if (containerRef.current) {
  //     const containerWidth = containerRef.current.offsetWidth;
  //     const containerHeight = containerRef.current.offsetHeight;
  //     const aspectRatio = containerWidth / containerHeight;
  //     const newNumCols = Math.floor(Math.sqrt(totalDivs * aspectRatio));
  //     const newNumRows = Math.ceil(totalDivs / newNumCols);
  //     setNumCols(newNumCols);
  //     setNumRows(newNumRows);
  //   }
  // }, [totalSubdivs]);
  // useEffect(() => {
  //   if (len !== 0) {
  //     setTotalSubdivs(totalDivs - len);
  //   }
   
  // }, [len, totalSubdivs]);

  // const gridStyle = useMemo(() => ({
  //   backgroundImage: `url(${bg})`,
  //   overflow: 'hidden',
  //   display: 'grid',
  //   gridTemplateColumns: `repeat(${numCols}, 1fr)`,
  //   gridTemplateRows: `repeat(${numRows}, 1fr)`,
  // }), [bg, numCols, numRows]);
  
  // useEffect(() => {
  //   calculateGridSize();
  //   const handleResize = () => {
  //     calculateGridSize();
  //   };
  //   let timeoutId;
  //   const debouncedResize = () => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       handleResize();
  //     }, 200); // Adjust the debounce time as needed
  //   };
  //   window.addEventListener('resize', debouncedResize);
  //   return () => {
  //     window.removeEventListener('resize', debouncedResize);
  //   };
  // }, [calculateGridSize]);
  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerReff.current) {
        const newWidth = containerReff.current.offsetWidth; // 80% of the container width
        setContainerWidth(newWidth);
      }
    }; 
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, [data]);
  const zoomPanRef = useRef(null);

 
  const autoZoomToSpecificDiv = async (divId) => {
    const targetDiv = await document.getElementById(String(ImageID));
   
    setTimeout(() => {
      const targetDiv = document.getElementById(String(ImageID));
      const targetDivAddedImage = document.getElementById(localStorage.getItem("addedImage"))
      if (targetDiv && zoomPanRef.current) {
        zoomPanRef.current.zoomToElement(targetDiv, 5, 1);
        targetDiv.style.border = "1px solid rgb(12, 182, 233)";
        targetDiv.style.opacity = "1";
        console.log(targetDiv);
      }
      if( localStorage.getItem("newElementID")){
        localStorage.removeItem("newElementID")
      }

      if (targetDivAddedImage && zoomPanRef.current) {
        zoomPanRef.current.zoomToElement(targetDivAddedImage, 5, 1);
        document.getElementById(localStorage.getItem("addedImage")).style.border = "1px solid rgb(12, 182, 233) ";
        document.getElementById(localStorage.getItem("addedImage")).style.opacity = "0.6";
       

        setTimeout(() => {
          document.getElementById(ImageID).style.border = "0px";
          document.getElementById(ImageID).style.opacity = "0.6";
          localStorage.removeItem("addedImage")
        }, 5000);
      }
    }, 500);


    if (targetDiv && zoomPanRef.current) {
      zoomPanRef.current.zoomToElement(targetDiv, 5, 1);
      localStorage.setItem("newElementID", ImageID);
      document.getElementById(ImageID).style.border = "1px solid rgb(12, 182, 233)";
      setTimeout(() => {
        document.getElementById(ImageID).style.border = "0px solid transparent";
        document.getElementById(ImageID).style.opacity = "0.6";
      }, 5000);
    }
  };

  useEffect(() => {
    
    const fetchDataAndZoom = async () => {
      await autoZoomToSpecificDiv(localStorage.getItem('newElementID') || String(ImageID));
    };
    fetchDataAndZoom();
  }, [ImageID]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoomSrc, setZoomSrc] = useState(null);
 
  useDebounceEffect(
    () => {
      let touchstartX = 0;
      let touchendX = 0;
  
      const swipeLeft = () => {
        setCurrentIndex(currentIndex + 1);
      };
  
      const swipeRight = () => {
        setCurrentIndex(currentIndex - 1);
      };
  
      function checkDirection() {
        let shouldSwipe = !zoomSrc;
  
        if (!shouldSwipe) {
          return;
        }
  
        const threshold = 70;
        const swipeDistance = Math.abs(touchstartX - touchendX);
        const isSwipe = swipeDistance >= threshold;
  
        if (!isSwipe) {
          return;
        }
  
        if (touchendX < touchstartX) {
          swipeLeft();
        } else if (touchendX > touchstartX) {
          swipeRight();
        }
      }
  
      const touchStart = (e) => {
        touchstartX = e.changedTouches[0].screenX;
      };
  
      const touched = (e) => {
        touchendX = e.changedTouches[0].screenX;
        checkDirection();
      };
  
      const containerElement = containerRef.current;
  
      if (containerElement) {
        containerElement.addEventListener("touchstart", touchStart);
        containerElement.addEventListener("touchend", touched);
      }
  
      return () => {
        if (containerElement) {
          containerElement.removeEventListener("touchstart", touchStart);
          containerElement.removeEventListener("touchend", touched);
        }
      };
    },
    [currentIndex, zoomSrc],
    {
      wait: 90,
    }
  );

  
  useEffect(() => {

      // Get the container element
      const container = containerRef.current;

      // Calculate the middle scroll position
      const middleScrollX = container.scrollWidth / 2 - container.clientWidth / 2;
      const middleScrollY = container.scrollHeight / 2 - container.clientHeight / 2;

      // Set the scroll position
      container.scrollLeft = middleScrollX;
      container.scrollTop = middleScrollY;
    
  }, [data]);
  
  
  return (
    <div ref={containerReff} style={{width:"100%", overflow:'hidden'}}>
     <TransformWrapper ref={zoomPanRef}
    disablePadding
    onZoomStart={() => {
        setZoomSrc(data[currentIndex]);
    }} options={{ pan: { panLimit: 1 } }}
    >
      <TransformComponent>
        <div style={{ width: `${containerWidth}px` }} ref={reff}>
          <div
            id="mosaic-container"
            ref={containerRef}
            className="p-0 m-0"
            disableMapInteraction
            // style={gridStyle}
            >
            <img src={imagebg} style={{position:'absolute',zIndex:-10,objectFit:'cover',imageOrientation:'from-image'}}></img>
            <div className='h-auto w-100 flex-wrap' style={{gridTemplateColumns:"repeat(1000, 1fr)",gap:0,display:'grid'}}>
            {data}
            </div>
     
          </div>
        </div>
        </TransformComponent>
    </TransformWrapper>
  </div>
  );
}
export default Loading;
