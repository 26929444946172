import React, { useEffect, useRef, useState, useNavigate, createRef } from "react";
import Modal from "react-modal";
import './Divimage.css';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as fasolidheart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faheart } from "@fortawesome/free-regular-svg-icons";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { faXmark, faStar,faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Uploading from "./uploading";
import Loading from "./maintool";
import { fetchData } from "./fetch";
import html2canvas from 'html2canvas';
import Swal from "sweetalert2";
import image1 from "../assest/th (1).jpeg"
import image2 from "../assest/Instagram_logo_2016.svg.png"
import image3 from "../logos/Elon Musk.jpeg"
import image4 from "../logos/download logo snapchat vector svg eps png psd ai.jpeg";
import SSlider from "./slider";
import Logos from './twologos'
import { Button } from "bootstrap";
import ARCSlider from "./arcsilder";
import ARCUploading from "./arcuploading";
import Form from 'react-bootstrap/Form';
import Footer from "./footer";
import ArcFooter from "./arcfooter";
import Select from 'react-select';
import flag1 from "../assest/vector-united-states-of-america-flag-usa-flag-america-flag-background.jpg"
import flag2 from "../assest/wp3534622.jpg"
import domtoimage from 'dom-to-image';
import crvimg from "../logos/top_swoop.svg"
import styles from './module.css';
import  PropagateLoader from "react-spinners/PropagateLoader";





// mosaic-container





function ImageZoom({ addedImagee, setAddedImages }) {
  
  const [open, setopen] = useState(false);
  const [imagessrc, setimagesrc] = useState('');
  const [data, setdata] = useState([])
  const [likecount, setlikecount] = useState(0)
  const [initialLikeCount, setInitialLikeCount] = useState(0);
  const [like, setlike] = useState(false);
  const [imageid, setimageid] = useState(0);
  const [idid, setidid] = useState(0)
  const [comments, setcomments] = useState([])
  const [publisher, getpublisher] = useState('')
  const [arr, setarr] = useState();
  const [add, setadd] = useState(false);
  const emailRef = useRef();
  const commentRef = useRef();
  const [addcomment, setaddcommet] = useState(false)
  const [email, setemail] = useState([]);
  const [enableZoomPan, setEnableZoomPan] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [putcommentss, setputcommentss] = useState();
  const [ids, setids] = useState([])
  const [Facebook, setfacebook] = useState('https://www.google.com/');
  const [instgram, setinstgram] = useState('https://www.google.com/');
  const [Twitter, settwitter] = useState('https://www.google.com/');
  const [linked, setlinked] = useState('https://www.google.com/');
  const [snap,setsnap]=useState('https://www.google.com/');
  const [date, setdate] = useState('')
  const [puttitle, setputtitle] = useState('')
  const [hasMatchingID, setHasMatchingID] = useState(false);
  const [priceapi, setpriceapi] = useState(0);
  const [offerapi, setofferapi] = useState(0);
  const [background, setbackgorund] = useState('')
  const [adminlike, setadminlike] = useState(false)
  const [offer_num, setoffnum] = useState(0);
  const [hold, sethold] = useState(0)
  const [arcwelcome,setarcwelcome]=useState('')
  const [welcome, setwelcome] = useState('')
  const [words, setwords] = useState([]);
  const [lang, setlang] = useState(false);
  const screenshotRef = useRef();
  const [commentlock,setcommentlock]=useState();
  const [newcomment,setnewcomment]=useState('')
  const [newemail,setnewemail]=useState('');
  const [lengthcomments, setlengthcomments]=useState(0);

  const downloadImage = async () => {
    const node = screenshotRef.current;
    domtoimage.toJpeg(node)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'screenshot.jpg';
        link.click();
      })
      .catch((error) => {
        console.error('Error creating screenshot:', error);
      });
  };

  
    
  


  const customStyles = {
    content: {
      width: '50%',
      height: '50%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
    }
  };



  function convertToArabicNumbers(englishNumbers) {
    const number = englishNumbers.toString();
    var arabicMap = {
      '0': '٠',
      '1': '١',
      '2': '٢',
      '3': '٣',
      '4': '٤',
      '5': '٥',
      '6': '٦',
      '7': '٧',
      '8': '٨',
      '9': '٩'
    };

    var arabicNumbers = '';
    for (var i = 0; i < number.length; i++) {
      var digit = number[i];
      arabicNumbers += arabicMap[digit] || digit;
    }

    return arabicNumbers;
  }
  // get Comments 
  function getcommentdata(lock){
    setlengthcomments(lengthcomments+1)
    let checkcomment = false
    if(lock==false){
      for (const word of words) {
        if ((newcomment).includes(word.word)) {
          Swal.fire({
            title: 'BOOKED',
            text: 'your comment contain a +18 word',
            icon: 'error',
            confirmButtonText: 'OK',
          });
          setnewemail('')
          setnewcomment('')
          checkcomment = true
          return true; // Comment contains forbidden word
        }
      }
      if (checkcomment == false) {
        setemail([...email,
        <>
          <p className="px-2 m-0">{newemail}:
          <p style={{ color: "gray", fontSize: '14px' }} className="px-0 m-0">{newcomment}</p>
          </p>
        </>
        ]);
  
        putcomment({ author: newemail, text: newcomment})
        setputcommentss({ author: newemail, text:newcomment })
  
        
        setnewcomment('')
        setnewemail('')
        setaddcommet(true)
        setadd(false)
      }
    }else{
      {lang =='true'? Swal.fire({
        title: 'locked',
        text: 'the comment locked',
        icon: 'warning',
        confirmButtonText: 'OK',
      })
      :
       Swal.fire({
        title: 'مغلق',
        text: 'التعليقات مفلقة',
        icon: 'warning',
        confirmButtonText: 'تاكيد',
      })
      ;}
    }
  }

  // Make Date formate 
  const convertDateFormat = (isoDate) => {
    const date = new Date(isoDate);

    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-indexed
    const year = date.getFullYear();

    // Padding single digit day/month with a leading zero if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  // Function Handle increase Num Like 
  const increaselikes = () => {
    // Calculate updated like count
    console.log('increaselikes', idid);
    var url = `https://snapus.pythonanywhere.com/api/like/create/${idid}/`;

    fetch(url, {
      method: 'PUT', // Use 'PUT' to match the Python code's HTTP method
    })
      .then(response => {
        if (response.status === 200) {
          console.log(response.status);
          return response.json();

        } else {
          throw new Error("Failed to increase likes");
        }
      })
      .then(data => {
        const numLikes = data.num_likes;


      })
      .catch(error => {
        console.error(error.message);
      });
  };

  // Function Handle Decrease Num Like 
  const decreaselikes = () => {
    // Calculate updated like count

    var url = `https://snapus.pythonanywhere.com/api/dislike/create/${idid}/`;

    fetch(url, {
      method: 'PUT', // Use 'PUT' to match the Python code's HTTP method
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Failed to decrease likes");
        }
      })
      .then(data => {
        const numLikes = data.num_likes;


      })
      .catch(error => {
        console.error(error.message);
      });
  };


  // Function To handle Add comment 
  const putcomment = async (comm) => {

    try {
      const response = await fetch(`https://snapus.pythonanywhere.com/api/comments/create/${idid}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(comm),
      });

      if (response.status === 201) {
        console.log('Comment successfully posted.');
        // Handle successful comment post
      } else {
        console.log('Failed to post the comment.');
        // Handle failed comment post
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };



  useEffect(() => {
    let jj = 0
    fetchData()
      .then((images) => {
        const dd = images;
        setdata(dd);
        setEnableZoomPan(true);
        setimageid(localStorage.getItem("addedImage"));
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });


  }, [initialLikeCount, putcommentss]);

  useEffect(() => {
    let jj = 0
    const fetchinfo = async () => {
      try {
        const response = await fetch('https://snapus.pythonanywhere.com/api/info/4/');
        const result = await response.json();

        // Assuming the response is in JSON format
        setpriceapi(result.price)
        setofferapi(result.offer)
        console.log(result.offer);
        console.log(result.offernum);
        setbackgorund(result.bg)
        setoffnum(result.offernum)
        sethold(result.holdspot)
        setwelcome(result.welmsg)
        setwords(result.bad_words)
        setarcwelcome(result.welmsgar)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchinfo();
  }, []);



  const getproduct = data.map((product, index) => {

    const productID = product.id;
    const matchingID = (localStorage.getItem('newElementID') || imageid) === productID.toString();
    const borderStyle = matchingID ? '1px solid rgb(12, 182, 233)' : '0px solid transparent';
    const opacityStyle = matchingID ? '1' : "0";
    return (
      <div
        className="tile p-0 m-0"
        key={product.id}
        disableMapInteraction
        id={product.id}
        style={{
          backgroundImage: `url(${product.image})`,
          
        }}
        onClick={() => {
          setimageid(product.id);
          setidid(product.id);
          setcomments(product.comments);
          setimagesrc(product.image);
          setlikecount(product.num_likes);
          setInitialLikeCount(product.num_likes);
          setopen(true);
          getpublisher(product.author);
          setEnableZoomPan(false); // now
          setdate(product.created_at);
          setfacebook(product.fk);
          setinstgram(product.inst);
          settwitter(product.tw);
          setlinked(product.ln);
          setsnap(product.sn)
          setputtitle(product.title)
          ids.includes(product.id) ? setlike(true) : setlike(false);
          setadminlike(product.isAdminLiked);
          setcommentlock(product.isDisable);
          setlengthcomments(product.comments.length);
        }}
      ></div>

    );
  });

  // The `useEffect` hook to remove `newElementID` after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      localStorage.removeItem('newElementID');
      setHasMatchingID(false);
    }, 5000);

    return () => clearTimeout(timeout); // Clear timeout on component unmount
  }, []);

  // Update the border style based on the hasMatchingID state
  useEffect(() => {
    const updatedBorderStyle = hasMatchingID ? '1px solid rgb(12, 182, 233)' : '0px ';
    const updatedopacityStyle = hasMatchingID ? '1' : '0.6';
    // Loop through the DOM elements and update their border style
    const divElements = document.querySelectorAll('.tile');
    divElements.forEach((element) => {
      element.style.opacity = updatedopacityStyle;
      element.style.border = updatedBorderStyle;
    });
  }, [hasMatchingID, imageid]);

  // The `useEffect` hook to remove `newElementID` after 5 seconds
  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem('newElementID');
      setHasMatchingID(false);
    }, 5000);
  }, []);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const readers = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();

      reader.onload = (fileReaderEvent) => {
        const fileData = fileReaderEvent.target.result;
        // Use the fileData here, e.g., upload it to a server or display it in the UI
      };

      reader.readAsDataURL(files[i]);
      readers.push(reader);
      setarr(Object.values(files))
    }
  }


  const searchResults = data.filter((item) =>
    item.author &&
    typeof item.author === 'string' &&
    item.author.toLowerCase().startsWith(searchQuery.toLowerCase())
  );


  const gotofacebook = () => {
    window.location.href = `${Facebook}`;
  };
  const gotoinstgram = () => {
    window.location.href = `${instgram}`;
  };
  const gotoTwitter = () => {
    window.location.href = `${Twitter}`;
  };
  const gotosnap = () => {
    window.location.href = `${snap}`;
  };


  const closeMOdal = () => {

    setopen(false);
    setimageid(0);
    setidid(0)
    // setEnableZoomPan(true)
    setcomments([])
    setemail([])
    setputcommentss([])
    setlike(false)
    setInitialLikeCount(likecount)
    setfacebook('')
    setinstgram('')
    settwitter('')
    setlinked('')
    setsnap('')
    setadminlike(false)
    setcommentlock('')
    setnewcomment('')
     setnewemail('')
  }



  function screenshot() {
    const divToDownload = document.getElementById('slides'); // Replace with the actual ID of your div

    if (!divToDownload) {
      console.error("Div not found");
      return;
    }

    const canvas = document.createElement('canvas');
    canvas.width = divToDownload.offsetWidth;
    canvas.height = divToDownload.offsetHeight;

    const context = canvas.getContext('2d');

    html2canvas(divToDownload, { useCORS: true }).then((canvasElement) => {
      context.drawImage(canvasElement, 0, 0);
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'div_screenshot.png';
      link.click();
    });

  }










  const options = [
    { value: 'true', label: <><img src={flag1} alt="English Flag" className="w-100 h-50"/></> },
    { value: 'false', label: <><img src={flag2} alt="Arabic Flag" className="w-100 h-50"/></> },
  ];
 const defaultOption = options[1];
const [langoptions,setlangoptions]=useState(defaultOption)

  const handleLangChange = (selectedOption) => {
    setlangoptions(selectedOption)
    setlang(selectedOption.value);
  };
  const customsStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 1px #007bff' : null,
      backgroundColor: '#222',
      color:'#f29122f1 !important',
      '&:hover': {
        border: '1px solid #007bff',
      },
    }),
    indicatorsContainer: () => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#222' : "#222",
      color: state.isSelected ? '#f29122f1' : 'black',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "12px", // Adjust top margin
      marginBottom: 0, // Adjust bottom margin
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0, // Adjust top padding
      paddingBottom: 0, // Adjust bottom padding
    }),
  };

  const customStylesModal = {
    content: {
      width: '25% ',
      height: '25rem ',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius:'20px ',
      padding:'0px',
      backgroundColor: "#222222e8",
      border: '1px solid #f29122f1',
     color:'#f29122f1',
    },
    overlay:{
      backgroundColor: "#2323238f",
    }
  };

  
  const [childData, setChildData] = useState(false);

  const handleChildData = (data) => {
    // Update the state with the new data from the child
    setChildData(data);
  };
  // useEffect(() => {
  //   // Perform an action in the parent when childData changes
  //   if (childData !== null) {
  //     console.log("Value from child changed. Do something in the parent.");
  //     // Perform any other actions or updates in the parent based on childData
  //   }
  // }, [childData]);


  return (
    <>
    {childData && (
            <div className="lay-loading w-100 d-flex justify-content-center align-items-center">
              <PropagateLoader color="#F29222" loading size={30} speedMultiplier={1} />
            </div>
          )}
   <div>
      <div id="tooldiv" >
      <img src={crvimg} className="crvimg" />
        <div>
          <div className="col-lg-12 text-center text-dark">
            <div className='d-flex justify-content-end mb-2'>
              <Select
                className="lang_select"
                options={options}
                value={langoptions}
                onChange={handleLangChange}
                isSearchable={false}
                styles={customsStyles}
              />
            </div>
            <div className="text-tool">
              <p style={{
                marginTop: 0,
                marginBottom: '1rem',
                fontSize: '24px',
                fontWeight: 700,
                letterSpacing: lang=='true'?'4px':'0px'
              }} 
              dir={lang!='true'&&'rtl'}
              >{lang=='true'?welcome:arcwelcome}</p>
            </div>
            <div className="main" id="panelID">
              <Logos lang={lang}/>

              {lang=='true'? 
              <div className="searchdiv mb-3  mt-2 d-flex justify-content-start" style={{ display: "flex !important" }} >
                {/* input for search */}
                <div className="form-floating mb-3  me-3 w-90 search_ff">
                  <input type="text" className="form-control" value={searchQuery} onChange={(e) => {
                    setSearchQuery(e.target.value)
                    setEnableZoomPan(false) //now
                  }} />
                  <label for="floatingInput" style={{ zIndex: 0 }}>search</label>
                </div>

                {searchQuery &&
                  <ul className="sr-output shadow">
                    {searchResults.map((result, index) => (
                      <>
                        <li key={index} style={{ display: "flex" }} onClick={() => {
                          debugger
                          console.log("result", result)
                          setimagesrc(result.image);
                          setlikecount(result.num_likes);
                          // setopen(true);
                          setimageid(result.id);
                          setidid(result.id);
                          setcomments(result.comments)
                          setSearchQuery('')
                          setEnableZoomPan(true)
                          setdate(result.created_at)
                          { ids.includes(result.id) ? setlike(true) : setlike(false) }
                          setfacebook(result.fk)
                          setinstgram(result.inst)
                          settwitter(result.tw)
                          setlinked(result.ln)
                          setputtitle(result.title)
                        }
                        }>
                          <img src={result.image} style={{ width: '50px', margin: '2px', height: '50px', margin: 'auto' }} />
                          <p style={{ width: "60%", height: "100%", textAlign: "center", display: 'inline-grid', alignItems: 'center' }}> {result.author}</p>
                        </li>
                        {searchResults.length - 1 == index ? null : <hr style={{ margin: 4 }} />}
                      </>
                    ))}
                  </ul>
                    }
                 <Uploading   onChildData={handleChildData} length={[...getproduct].length} holdspot={hold} addedImagee={addedImagee} price={priceapi} offer_number={offer_num} offer={offerapi} setAddedImages={setAddedImages} data={data} ImageID={imageid} setImageID={setimageid} />
                  
                <a
                  className="btn btn-primary upload-btn ms-2 d-flex align-items-center justify-content-center DOWNLOAD_BTN"
                  style={{ letterSpacing: lang == 'true' == false && 0 }}
                  href="https://snapus.pythonanywhere.com/api/generate-mosaic/"
                >
                   Get Board 
                </a>
              </div>
              
              :

              <div className="searchdiv mb-3  mt-2 d-flex justify-content-end" style={{ display: "flex !important" }} >
               <a
                  className="btn btn-primary upload-btn ms-2 d-flex align-items-center justify-content-center DOWNLOAD_BTN"
                  style={{ letterSpacing:  0 }}
                  href="https://snapus.pythonanywhere.com/api/generate-mosaic/"
                >
                   تنزيل
                </a>
                
                 <ARCUploading onChildData={handleChildData} length={[...getproduct].length} holdspot={hold} addedImagee={addedImagee} price={priceapi} offer_number={offer_num} offer={offerapi} setAddedImages={setAddedImages} data={data} ImageID={imageid} setImageID={setimageid} />
                

                 {/* input for search */}
                 <div className="form-floating mb-3  me-3 w-90 search_ff">
                  <input type="text" className="form-control" value={searchQuery} onChange={(e) => {
                    setSearchQuery(e.target.value)
                    setEnableZoomPan(false) //now
                  }} dir="rtl"/>
                  <label for="floatingInput" style={{ zIndex: 0 }}>{lang == 'true' ? "search" : "البحث"}</label>
                </div>
                {searchQuery &&
                  <ul className="arsr-output shadow">
                    {searchResults.map((result, index) => (
                      <>
                        <li key={index} style={{ display: "flex" }} onClick={() => {
                          debugger
                          console.log("result", result)
                          setimagesrc(result.image);
                          setlikecount(result.num_likes);
                          // setopen(true);
                          setimageid(result.id);
                          setidid(result.id);
                          setcomments(result.comments)
                          setSearchQuery('')
                          setEnableZoomPan(true)
                          setdate(result.created_at)
                          { ids.includes(result.id) ? setlike(true) : setlike(false) }
                          setfacebook(result.fk)
                          setinstgram(result.inst)
                          settwitter(result.tw)
                          setlinked(result.ln)
                          setputtitle(result.title)
                          setcommentlock(result.isDisable)
                        }
                        }>
                          <img src={result.image} style={{ width: '50px', margin: '2px', height: '50px', margin: 'auto' }} />
                          <p style={{ width: "60%", height: "100%", textAlign: "center", display: 'inline-grid', alignItems: 'center' }}> {result.author}</p>
                        </li>
                        {searchResults.length - 1 == index ? null : <hr style={{ margin: 4 }} />}
                      </>
                    ))}
                  </ul>
                  
                  }
              </div>
}


              <div className="w-100">
                <Loading reff={screenshotRef} data={getproduct} bg={background} len={[...getproduct].length} ImageID={imageid} setImageID={setimageid} />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {lang == 'true' ? <SSlider setImageID={setimageid} /> : <ARCSlider setImageID={setimageid} />}

      {lang == 'true' ? <Footer /> : <ArcFooter />}

      <Modal isOpen={open} ariaHideApp={false} style={customStylesModal} onRequestClose={closeMOdal} >
        <div className="Modal " style={{ overflowY: 'hidden' }}>
          <div className="d-flex justify-content-between align-items-center p-2" dir={lang != 'true' ? 'rtl' : 'ltr'}>
              <div>
              <p className="px-2 m-0 publisher " dir={lang != 'true' ? 'rtl' : 'ltr'} style={{color:'#f29122f1',fontSize:18}}><span>{publisher}</span></p>

              {lang != 'true' ? <p className="date px-2 m-0">{convertToArabicNumbers(convertDateFormat(date))} </p>
              :
              <p className="date px-2 m-0">{convertDateFormat(date)}</p>
              }
              <p className="Description m-0 p-0 px-2" dir={lang != 'true' ? 'rtl' : 'ltr'}><span style={{color:'gray'}}>{puttitle}</span></p>

              </div>
            <FontAwesomeIcon className="p-2 m-0 " style={{color:'#f29122f1'}} icon={faXmark} onClick={() => {
              setopen(false);
              setimageid(0);
              setidid(0)
              setEnableZoomPan(true)
              setcomments([])
              setemail([])
              setputcommentss([])
              setlike(false)
              setInitialLikeCount(likecount)
              setfacebook('#')
              setinstgram('#')
              settwitter("#")
              setlinked("#")
              setadminlike(false)
              setcommentlock('')
              setnewcomment('')
              setnewemail('')
                ;
            }} />

          </div>
          <div className="col-12" style={{height:'45%',backgroundColor:'black'}}>
            <img src={imagessrc} style={{ width: "100%", margin: "auto",height:'100%' ,objectFit:'contain'}} />
          </div>

          <div className="row mx-1 " dir={lang != 'true' ? 'rtl' : 'ltr'}>
            <div className="col-6" style={{color:'#f29122f1'}}>
            {lang == 'true' ?<p className="p-0 mb-0">{likecount} <span style={{color:'gray'}}>Likes</span></p> : <p className="p-0 mb-0">{convertToArabicNumbers(likecount)} <span style={{color:'gray'}}>الاعجابات</span></p>}
            </div>
            <div className="col-6 d-flex justify-content-end" style={{color:'#f29122f1'}}>
            {lang == 'true' ?<p className="p-0 mb-0">{lengthcomments} <span style={{color:'gray'}}>Comments</span></p> : <p className="p-0 mb-0">{convertToArabicNumbers(lengthcomments)} <span style={{color:'gray'}}>التعليقات</span></p>}
            </div>

            <hr  className="p-0 m-0" style={{color:'#f29122f1'}}/>
        <div className="d-flex p-1">
           <p className="likes ps-2 col-6 m-0" dir={lang != 'true' ? 'rtl' : 'ltr'} style={{color:'#f29122f1'}}>
                 {
                    like ? (
                      <FontAwesomeIcon
                        icon={fasolidheart}
                        onClick={() => {
                          setlike(false)
                          decreaselikes()
                          setlikecount(likecount - 1)
                          setids(ids.filter(item => item !== idid))
                          console.log(imageid);
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faheart}
                        onClick={() => {
                          setlike(true)
                          increaselikes()
                          setlikecount(likecount + 1)
                          setids([...ids, idid])
                        }}
                      />
                    )
                  }
                 
             </p>
             <div className="social_limk d-flex justify-content-end  col-6 ">
                <div>
                  <img src={image1} className="rounded-circle mx-1" onClick={gotofacebook} />
                  <img src={image2} className="rounded-circle mx-1" onClick={gotoinstgram} />
                  <img src={image3} className="rounded-circle mx-1" onClick={gotoTwitter} />
                  <img src={image4} className="rounded-circle mx-1" onClick={gotosnap} />

                </div>
              </div>
            </div>  
          </div>
          <div >
            <h5 style={{fontWeight:300}} className="px-2" dir={lang == 'true' ? 'ltr' : 'rtl'}>{lang == 'true' ? 'Comments' : 'التعليقات'}</h5>
            <div className="commentlist px-2" dir={lang == 'true' ? 'ltr' : 'rtl'} style={{overflowY:'auto',height:'4.3rem',wordWrap:'break-word'}}>
            <style>
          {`
            /* WebKit browsers (Chrome, Safari) */
            ::-webkit-scrollbar {
              width: 0.5rem;
            }

            ::-webkit-scrollbar-thumb {
              background-color: transparent;
            }

            /* Firefox */
            scrollbar-width: thin;
          `}
        </style>
                  {comments.length > 0 && comments.map((comment) => {
                    return (
                      <>
                        <p className="px-2 m-0">
                          {comment.author === 'Adminsecret' ? "Admin" :`${comment.author} :`}{comment.author === 'Adminsecret' && <FontAwesomeIcon icon={faStar} style={{ color: "#7ddbf2" }} />}
                          <p style={{ color: "gray", fontSize: '14px' }} className="px-0 m-0 mb-2">{comment.text}</p>
                        </p>
                        <hr className="p-0 m-0"/>
                      </>
                    )
                  })}
                  {email.length > 0 ? email.map((ele) => {
                    return <p>{ele}</p>
                  }) : null}
                </div>
            
          </div>
          <div className="w-100" style={{position:'absolute',bottom:'0%'}} dir={lang == 'true' ? 'ltr' : 'rtl'}>
             <div className="d-flex justify-content-around align-items-center pb-2 pt-2" style={{backgroundColor:'#0f0d0d5c'}}> 
                <input type="text" className="col-5 rounded p-1" placeholder= {lang == 'true' ? "Name" : 'الاسم'} style={{color:"#f29122f1"}} value={newemail} onChange={(e)=>setnewemail(e.target.value)}/>
                <input type="text" className="col-5 rounded p-1"  placeholder= {lang == 'true' ? "Comment" : 'التعليق'} style={{color:"#f29122f1"}} value={newcomment} onChange={(e)=>setnewcomment(e.target.value)} />
                
                 <FontAwesomeIcon icon={faArrowTurnUp} style={{color:"#f29122f1"}}  onClick={()=>getcommentdata(commentlock)}/>
             </div>
        </div>


          
        </div>
      </Modal>
      </div>
    </>
  );
};

export default ImageZoom;